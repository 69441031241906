import React, { Component } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      user:         params.user,
      locality:     params.locality,

    };
    this.handleUserChange = params.handleUserChange;
  }
  render() {
    let locality = null;
    if(this.state.locality && 
        this.state.locality.country) {
      locality = (<div className="footer-locality">
        <img alt={this.state.locality.country.name + "'s flag"} className="footer-locality-flag" src={this.state.locality.country.flag} />
        <span className="footer-locality-text">{this.state.locality.country.name} (<strong>{this.state.locality.remoteip}</strong>)</span>
        <div className="clearfix"></div>
      </div>);
    }
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer-meta">
            <p className="footer-business-info">© {new Date().getFullYear()} HuskyCodes Oy (3195113-2)</p>
          </div>
          <div className="footer-actions">
            <div className="footer-links">
              <a href="https://twitter.com/huskycodes" className="footer-link">
                <span className="fab fa-twitter-square"></span>
              </a>
              <a href="https://www.facebook.com/HuskyCodes-104219868407125/" className="footer-link">
                <span className="fab fa-facebook-square"></span>
              </a>
              <a href="mailto:info@huskycodes.com" className="footer-link">
                <span className="fas fa-envelope"></span>
              </a>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </footer>
    );
  }
}

export default App;
