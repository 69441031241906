import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const productMeta = {
    gathermuch: {
        bgColor: '#fff5db',
        logoColor: '#f58c1b'
    },
    aurorareach: {
        bgColor: '#EFF5FB',
        logoColor: '#2C497F'
    },
    aurorareachApp: {
        bgColor: '#F4F2F8',
        logoColor: '#534077'
    }
}
class fullWidthProduct extends Component {
  render() {
    const meta = productMeta[this.props.productSlug]
    const appMeta = this.props.appMeta
    const imgstyle = {};
    if(this.props.maxHeight) imgstyle.maxHeight = this.props.maxHeight;
    if(this.props.marginBottom) imgstyle.marginBottom = this.props.marginBottom;
    return (
        <div className="container">
            <div className="product-container" style={{backgroundColor: meta.bgColor}}>
                <div className="product-description">
                    <p className="product-type" style={{color: meta.logoColor}}>{this.props.type.toUpperCase()}</p>
                    <p className="product-title" >{this.props.title}</p>
                    <p className="product-text" >{this.props.text}</p>
                    <div className="app-buttons-container">
                    {(appMeta && appMeta['playstore']) ? 
                        <a className="app-button" href={appMeta['playstore'].url}>
                            <img alt={ this.props.productSlug + " Play Store Link"} src={appMeta['playstore'].image}></img>
                        </a>
                        :
                        null
                    }
                    {(appMeta && appMeta['appstore'] )? 
                        <a className="app-button" href={appMeta['appstore'].url}>
                            <img alt={ this.props.productSlug + " App Store Link"} src={appMeta['appstore'].image}></img>
                        </a>
                        :
                        null
                    }
                    <div className="clearfix"></div>
                    </div>
                    {this.props.productURL ?
                        <a className="product-url" target="_blank" href={this.props.productURL} style={{color: meta.logoColor}}>Visit Website <span className="fas fa-external-link-alt product-link-icon"></span></a>
                        :
                        null
                    }
                </div>  
                <div className="product-images">
                    <img src={this.props.imageURL} className="product-image" style={imgstyle}></img>
                </div>
                <div className="clearfix"></div>
            </div>

        </div>

    );
  }
}

export default fullWidthProduct;
